.section {
    margin-top: 120px;
    text-align: center;
  }

  p span {
    font-weight: 700;
  }
  
  .details h3 {
    margin-bottom: 20px;
  }
  .details p {
    margin-bottom: 20px;
  }
  
  .details a {
    text-transform: lowercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: none;
    color: $clrPry4; 
  }
  
@media screen and (max-width: 1024px) {
    .container{
      text-align: center;
    }
  }