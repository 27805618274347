.artists-container {
  width: $max-width;
  margin: 0 auto;
}

.article {
  margin-bottom: 2rem;
  p {
    text-align: center;
  }
}

.artist-header {
  margin-top: 150px;
  margin-bottom: 2rem;
  text-align: center;
}

.cards-artists {
  margin-top: 50px;
  margin-bottom: 100px;
}

.card-artists {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 20px; /* Added */
  text-align: center;
  height: 37rem;
  width: 25rem;
  border-radius: $radius;
  padding: 20px 20px;
  transition: $transition !important;

  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-radius: $radius;
    box-shadow: $light-shadow;
    margin-bottom: 1rem;
  }
}

.card-container {
  margin: 0 auto;
  width: $max-width;
}

.artist-all {
  padding: 20px 30px;
  background-color: $boxclr;
  transition: $transition !important;
}

.desc-artists {
  width: 100%;
  height: 12rem;
  text-align: center;
  margin: 0 auto;
  transition: $transition !important;
  p {
    margin-top: 10px;
    letter-spacing: 1.2px;
    font-size: 0.8rem;
    text-transform: none;
    margin-bottom: 10px;
  }
}

.line {
  border-top: 1px solid black;
  margin: 20px auto;
  width: 20%;
}

.artist-details {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  transition: $transition !important;
  p {
    margin-top: 10px;
    letter-spacing: 1.2px;
    font-size: 0.8rem;
    text-transform: none;
  }
}

.card-artists:hover {
  background: $clrPry4 !important;
  transform: translateY(-3px) !important;

  p,
  h3 {
    color: white;
  }
  .line {
    border-color: white;
  }
  .artist-all {
    background: $clrPry4;
  }
}

.notification {
  position: relative;
  display: flex;
  justify-content: center;
}

.button-line-btn {
  width: 90%;
  height: 4px;
  border-radius: 4px;
  background: $clrHead;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s cubic-bezier(0.2, 1.5, 0.2, 1.5);
}
.button-line-btn:hover {
  width: 40%;
}

.btn-video {
  position: absolute;
  border: none;
  top: 100px;
  cursor: pointer;
  transition: $transition;
  padding: 15px 20px;
  font-weight: 600;
  font-size: 20px;
  border-radius: 4px;
  letter-spacing: $spacing;
  z-index: 1;
  color: $clrHead !important;
}

.btn-video:hover {
  opacity: 1;
}

.notification .btn-video {
  background: white;
  opacity: 0;
  transition: $transition;
}

.notification:hover .btn-video {
  opacity: 0.7;
  transition: $transition;
  transform: translateY(-3px) !important;
}

.notification .btn-video:hover {
  transition: $transition;
  background: white;
  opacity: 0.9;
  transform: translateY(0px) !important;
  .button-line-btn {
    width: 40%;
  }
}

.clearFloat {
  clear: both;
}

@media screen and (max-width: 1200px) {

  .card-artists {
    height: 37.8rem;
  }
}


@media screen and (max-width: 991px) {
  .artists-container {
    width: 700px;
    margin: 0 auto;
  }

  .card-artists {
    height: 37rem;
  }
}

@media screen and (max-width: 700px) {
  .artists-container {
    width: 400px;
    margin: 0 auto;
  }

  .card-artists {
    height: 37rem;
  }
}
