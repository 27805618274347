.banner {
  background-size: cover;
  background-position: center;
  height: 76%;
  position: relative;
}

.container-start {
  margin: 0 auto;
  // height: 100vh;
  // width: 100%;
  // max-width: $max-width;
  height: calc(100vh - 70px);
//   height: -o-calc(100vh - 70px); /* opera */
//   height: -webkit-calc(100vh - 70px); /* google, safari */
//   height: -moz-calc(100vh - 70px); /* firefox */

}

.header-start {
  text-align: center;
  margin-top: 9rem;
  margin-bottom: 1rem;
}

.button-story {
  // left: 0;
  // right: 0;
  margin-left: auto;
  margin-right: auto;
  transition: $transition;
  width: max-content;
  position: absolute;
  // bottom: 5rem;
  cursor: pointer;

  a {
    color: white;
    transition: $transition;
    font-weight: 600;
  }
}

.button-line-start {
  width: 90%;
  height: 4px;
  margin-top: 0.5rem;
  border-radius: 4px;
  background: white;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s cubic-bezier(0.2, 1.5, 0.2, 1.5);
}
.button-story:hover {
  transform: translateY(-5px);

  .button-line-start {
    width: 40%;
  }
}

// @media screen and (max-width: 360px) {
//   .container-start{
//     width: 400px;
//   }
// }

// @media screen and (max-width: 700px) {
//   .container-start{
//     width: 400px;
//   }
// }