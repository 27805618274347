.story-container {
  width: $max-width;
  margin: 0 auto;
  // height: 100vh;
}

.header {
  margin-top: 150px;
  margin-bottom: 2rem;
  text-align: center;
}
.story-article p {
  font-size: 1rem;
  font-weight: lighter;
}

.rbb-video {
  //   margin: 0 auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.story-img {
  width: 450px;
  height: 350px;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  border-radius: 4px;
}

.story-article {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 100px;
  align-items: center;
}

.partner-logos {
  justify-content: center;
  align-items: center;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

.partner {
  margin-top: 50px;
}

.partner-logo {
  height: 100%;
  width: 200px;
  margin: 40px;
  margin-bottom: 100px;
  // background-position: center;
  // background-size: cover;
  object-fit: contain;
  // background-repeat: none;
}

.video-wrapper {
  padding-top: 70px;
  padding-bottom: 70px;
  width: 900px;
  margin: 0 auto;
}

.story-video {
  width: 100%;
  height: 490px;
}

@media screen and (max-width: 700px) {
  .story-container {
    width: 400px;
    margin: 0 auto;
  }
  .video-wrapper {
    padding-top: 70px;
    padding-bottom: 70px;
    width: 400px;
    margin: 0 auto;
  }
  .story-video {
    width: 100%;
    height: 230px;
  }
  .partner-logo {
    margin-bottom: 50px;
  }
}
