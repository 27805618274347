.service-container {
  margin: 0 auto;
  max-width: $max-width;
  height: 100%;
  // margin-bottom: 200px;
}

// .bulliframe-wrapper {
//   max-width: 1000px;
//   margin: 0 auto;
//   height: 550px;
// }

.bulliframe {
  position: relative;
  display: flex;
  // justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  background-image: url(../../images/bulliframe.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
}

.bulli-service {
  width: 100%;
  margin: 0 auto;
}

.frame-content {
  position: absolute;
  left: 60px;
  h3 {
    min-width: 100%;
    font-size: 2.6rem;
    // font-size: 2.3vw;
    hyphens: auto;
  }
  p {
    margin-bottom: 1vw;
    line-height: 2.4rem;
    font-size: 2rem;
    // font-size: 1.9vw;
    font-weight: 500;
    font-family: 'Jost';
    letter-spacing: 0.1vw;
  }
  // :nth-child(1) {
  //   top: 4vw;
  //   left: 4vw;
  // }
  // :nth-child(2) {
  //   top: 14vw;
  //   left: 4vw;
  // }
  // :nth-child(3) {
  //   top: 24vw;
  //   left: 4vw;
  // }
}

.button-artists {
  // border: 4px solid black;
  background-color: #E8E8E8;
  border-radius: 3rem;
  margin: 0 auto;
  width: max-content;
  margin-top: 80px;
  cursor: pointer;
  transition: $transition;
  padding: 15px 30px;
  background-image: linear-gradient(
    to right,
    transparent,
    transparent 50%,
    $clrPry4 50%,
  );
  background-size: 200%;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-play-state: running;
  a {
    transition: $transition;
    font-weight: 600;
  }
}

// .button-line-artist {
//   width: 90%;
//   height: 4px;
//   margin-top: 0.5rem;
//   margin-bottom: 0.5rem;
//   background: $clrHead;
//   border-radius: 4px;
//   margin-left: auto;
//   margin-right: auto;
//   transition: 0.4s cubic-bezier(0.2, 1.5, 0.2, 1.5);
// }

// .arrow{
//   font-size: 35px;
//   line-height:small;
// }

.button-artists:hover {
  background-position-x: 100%;
  // box-shadow: $light-shadow;
  // transform: translateY(-5px);
  // border: 4px solid #f15025;

  a {
    color: white;
  }
  .button-line-artist {
    width: 40%;
    background-color: $clrPry4;
  }
}

@media screen and (max-width: 1250px) {
  .frame-content h3 {
    font-size: 2.3vw;
  }
  .frame-content p {
    margin-bottom: 1vw;
    line-height: 2vw;
    font-size: 1.9vw;
    letter-spacing: 0.1vw;
  }
}

// @media screen and (max-width: 700px) {
//   .service-container{
//     width: 400px;
//   }
// }
