.header-kontakt {
  // padding-top: 100px;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 100px;
}

.header a {
  color: $clrPry4;
  text-transform: none;
  font-size: 14px;
  margin-top: 5px;
  letter-spacing: 0.1rem;
}
.header p {
  letter-spacing: 0.05rem !important;
  line-height: 22px;
  font-size: 14px;
}

.MuiInputLabel-outlined {
  z-index: 0 !important;
}

.formwrapper {
  margin: 50px auto;
  width: 700px;
  text-align: center;
  margin-bottom: 100px;
}

.button-senden {
  display: flex;
  align-items: center;
  background: #e8e8e8;
  text-transform: uppercase;
  color: $clrPara;
  border-radius: 3rem;
  margin: 0 auto;
  width: max-content;
  margin-top: 80px;
  cursor: pointer;
  transition: $transition;
  padding: 15px 25px;
  letter-spacing: $spacing;
  font-weight: 600;
  font-size: 1.2rem;
  border: none;
  input {
    transition: 0.2s;
    font-weight: 700;
    letter-spacing: $spacing;
  }
  span{
    transition: $transition;

  }
}

.button-modal {
  margin: 0 auto;
  width: max-content;
  margin-top: 40px;
  // margin-bottom: 290px;
  cursor: pointer;
  text-decoration: none;
  // color: $clrPara;
  color: $clrPara;
  text-transform: uppercase;
  letter-spacing: $spacing;
  font-weight: 600;
  font-size: 1.2rem;
  transition: $transition;
  p {
    color: $clrPara;
    font-family: 'Jost', 'Open Sans', sans-serif;
    transition: $transition;
  }
}

.modal-body {
  text-align: center;
}

.modal-header {
  justify-content: center !important;
}
.modal-content {
  background-color: $mainBg;
}

.senden {
  text-decoration: none;
  color: $clrPara;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: $spacing;
  font-weight: 600;
  font-size: 1.2rem;
  border: none;
}

// .button-line {
//   width: 80%;
//   height: 4px;
//   margin-top: 0.5rem;
//   background: $clrHead;
//   margin-left: auto;
//   border-radius: 4px;
//   margin-right: auto;
//   transition: 0.4s cubic-bezier(0.2, 1.5, 0.2, 1.5);
// }

.button-senden:hover {
  // transform: translateY(-5px);
  background: $clrPry4;
  color: white;
  .button-line {
    width: 40%;
    background-color: $clrPry4;
  }
  .senden {
    color: white;
  }
  .arrowIcon {
    // transition: $transition;
    color: white;
    transform: translateX(7px);
  }
}

.button-modal:hover {
  transform: translateY(-5px);
  p {
    color: $clrPry4;
  }

  .button-line {
    width: 40%;
    background-color: $clrPry4;
  }
}

/////////////////// Color Correction Form
.MuiPickersDay-daySelected {
  background-color: $clrPara !important;
}
.MuiPickersToolbar-toolbar {
  background-color: $clrPara !important;
}
.MuiFormLabel-root.Mui-focused {
  color: $clrPara !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $clrPara !important;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-family: 'Open Sans', sans-serif;
}

////////////////////////////////

@media screen and (max-width: 860px) {
  .formwrapper {
    margin: 50px auto;
    width: 550px;
    text-align: center;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 860px) {
  .formwrapper {
    margin: 50px auto;
    width: 400px;
    text-align: center;
    margin-bottom: 100px;
  }
}

// @media screen and (max-width: 700px) {
//   #kontakt{
//     width: 400px;
//   }
//   .MuiGrid-spacing-xs-2{
//     width: 400px !important;
//   }
// }
