
ul {
  padding-left: 0 !important;
}
.navbar-logo {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 11rem;
}

.logo {
  width: 127px;
}

.logo-wrapper {
  justify-content: center;
  height: 70px;
  width: 126px;
  display: flex;
  align-items: center;
  transition: 5s;
}
.NavbarItems {
  background: #f1f1f1;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  position: fixed;
  z-index: 2;
}

.nav-menu {
  display: flex;
  position: relative;
  justify-content: space-evenly;
  width: 900px;
  margin-bottom: 0;
  height: 70px;
  align-items: center;
}

.nav-links {
  color: black;
  font-size: 0.8rem;
  line-height: 70px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all .2s linear;

}

#frame{
  position: absolute;
  height: 100%;
  top: 0;
  z-index: -2;
  opacity: 1;
  background-image: url(../../images/Logo_ohneName.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 126px;
  transition: all .5s ease 0s;
}

.hidden {
  opacity: 0 !important;
  left: -17.9%;
}

.frame1 {
  transition: left .2s;
}

.frame2 {
  transition: left .2s;
  left: 42.7%;
}

.frame3 {
  transition: left .2s;
  left: 64.2%;
}

.nav-links:hover {
  color: #212121;
  cursor: pointer;
}

.menu-icon {
  display: none;
}

.social-icons {
  display: flex;
  width: 11rem;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
}
.social-icons li {
  transition: 0.2s;
  
}
.social-icons a {
  transition: 0.2s;
}

.social-icons a:hover {
  transform: translateY(-5px);
  color: #f15025;
  cursor: pointer;
}

.social-icons li:hover {
  transform: translateY(-5px);
}

/* Styling Hamburger Icon
.hamburger div{
  width: 30px;
  height:3px;
  background: #212121;
  margin: 5px;
  transition: all 0.3s ease;
}
.hamburger{
  display: none;
} */



/* MOBILE RESPONSIVE */


@media screen and (max-width: 1050px) {
  .NavbarItems {
    position: fixed;
  }

  .nav-menu {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 100px;
    right: -100%;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    justify-content: flex-start;
    background: #212121;
    width: 100%;
    right: 0;
    height: 100vh;
    opacity: 1;
    transition: all 0.4s ease;
    z-index: 1;
    margin: 0;
    padding-top: 20px;
    position: absolute;
  }


  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #f1f1f1;
    font-size: 1.4rem;
  }

  .nav-links:hover {
    transform: translateY(-5px);
    color: #f1f1f1;
    transition: all 0.2s ease;
  }

  #frame {
    opacity: 0 !important;
  }

  .social-icons li {
    display: none;
  }

  .navbar-logo {
    position: absolute;
    display: flex;
    /* justify-content: center; */
    /* top: 0; */
    /* left: 35%; */
    /* transform: translate(0, 25%); */
    
  }

  /* #frame {
    opacity: 0;
  } */

  .menu-icon {
    display: block;
    position: absolute;
    top: 25%;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
    font-size: 1.5rem;
  }

  /* .hamburger{
    display:block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
} */
  
  /*Animating Hamburger Icon on Click*/
/* .toggle .line1{
  transform: rotate(-45deg) translate(-5px,6px);
}
.toggle .line2{
  transition: all 0.1s ease;
  width:0;
}
.toggle .line3{
  transform: rotate(45deg) translate(-5px,-6px);
} */

}

