// My CSS partials

// components
@import "colors";
@import "components/navbar";
@import "components/start";
@import "components/mieten";
@import "components/service";
@import "components/kontakt";
@import "components/footer";
@import "components/youtube";
@import "components/modal";
@import "components/video";
@import "components/button";

// pages
@import "pages/artists";
@import "pages/story";
@import "pages/impressum";
@import "pages/datenschutz";
@import "pages/impressions";

@import "node_modules/react-modal-video/scss/modal-video.scss";

/*
  =============== 
  Global Styles
  ===============
  */

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Jost", "Open Sans", sans-serif;
  background-color: $mainBg;
  color: $clrPara;
  line-height: 1.5;
  font-size: 0.875rem;
  // max-width: $max-width;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: $clrPara;
  text-transform: uppercase;
  letter-spacing: $spacing;
  font-weight: 600;
  font-size: 1.25rem;
}
h1,
h2,
h3,
h4 {
  letter-spacing: $spacing;
  text-transform: uppercase;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  color: $clrHead;
  margin: 0;
  font-weight: 600;
  hyphens: auto;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 4rem;
}
p {
  margin: 0;
  color: $clrPara;
  font-family: "Open Sans", sans-serif;
}

// So Bootstrap cannot influence <TextField> from Materail UI in "Kontakt"
legend {
  float: unset;
}

@media screen and (max-width: 540px) {
  .view {
    width: 400px;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .view {
    width: 600px;
  }
}

@media screen and (max-width: 700px) {
  h1 {
    font-size: 2.2rem;
    // hyphens: auto
  }

  h2 {
    font-size: 2.5rem;
  }
  // h3 {
  //   font-size: 1.75rem;
  // }
  h4 {
    font-size: 2.2rem;
    // hyphens: auto
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.25;
  }
}

// @media screen and (max-width: 400px) {
//   #root {
//     width: 400px;
//     margin: 0 auto;
//     padding-left: 12px;
//     padding-right: 12px;
//   }
// }
