.container-mieten {
  margin: 0 auto;
  max-width: $max-width;
  height: 100%;
}

.mieten-header {
  margin-top: 100px;
  margin-bottom: 2rem;
  text-align: center;
}

.wrapper {
  margin: 0 auto;
  width: $max-width;
  position: relative;
}

.cards {
  display: flex;
  justify-content: space-around;
  // max-width: 100%;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .card {
      width: 22rem !important;
      img {
        height: 330px !important;
      }
    }
  }
}

.card {
  text-align: center;
  width: 22rem;
  height: 500px;
  border-radius: $radius;
  padding: 20px 20px;
  transition: $transition !important;
  background-color: $mainBg;
  border: none;
  img {
    margin: 0 auto;
    width: 100%;
    height: 330px;
    object-fit: cover;
    border-radius: $radius;
    box-shadow: $light-shadow;
    margin-bottom: 1rem;
  }
}

.desc {
  width: 100%;
  margin: 0 auto;
  background-color: $boxclr;
  padding: 20px 10px;
  transition: $transition !important;
  p {
    margin-top: 10px;
    letter-spacing: 1.2px;
    font-size: 0.8rem;
    transition: $transition;
    font-weight: 300;
  }
  h3 {
    transition: $transition;
  }
}

.card:hover {
  background: $clrPry4 !important;
  transform: translateY(-3px) !important;
  p,
  h3 {
    color: white;
  }
  .desc {
    background: $clrPry4;
  }
}

/* ALT */
/*------------------------*/
// .button-impressionen {
//   margin: 0 auto;
//   width: max-content;
//   margin-top: 30px;
//   cursor: pointer;
//   transition: $transition;
//   padding: 10px 20px;
//   a {
//     transition: $transition;
//     font-weight: 700;
//   }
// }

// .button-line-mieten {
//   width: 90%;
//   height: 4px;
//   margin-top: 0.5rem;
//   background: $clrHead;
//   margin-left: auto;
//   margin-right: auto;
//   border-radius: 4px;
//   transition: 0.4s cubic-bezier(0.2, 1.5, 0.2, 1.5);
// }

// .button-impressionen:hover {
//   transform: translateY(-5px);
//   a {
//     color: $clrPry4;
//   }
//   .button-line-mieten {
//     width: 40%;
//     background: $clrPry4;

//   }
// }
/*--------------------------------*/

// @media screen and (max-width: 1055px) {
//   .container-mieten{
//     display: inline-block;
//     // margin: auto;
//   }

// }

// .button-impressionen {
//   margin: 0 auto;
//   width: max-content;
//   margin-top: 3em;
//   transition: 0.3s;
//   cursor: pointer;

//   background-color: #e8e8e8;
//   border: 0;
//   border-radius: 4px;
//   padding-left: calc(4 * 9px);
//   padding-right: calc(4 * 9px);
//   padding-top: calc(2 * 9px);
//   padding-bottom: calc(2 * 9px);
//   line-height: 1;
//   font-weight: 500;
//   text-decoration: none;
//   a {
//     transition: $transition;
//   }
// }

// .button-impressionen:hover {
//   a {
//     color: white;
//   }
//   box-shadow: inset 18em 0 0 0 $clrPry4;
// }

.button-impressionen {
  background-color: #E8E8E8;
  border-radius: 4px;
  margin: 0 auto;
  width: max-content;
  margin-top: 80px;
  cursor: pointer;
  // transition: $transition;
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  padding: 15px 30px;
  background-image: linear-gradient(
    to right,
    transparent,
    transparent 50%,
    $clrPry4 50%,
  );
  background-size: 200%;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: in;
  a {
    // transition: $transition;
    transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    font-weight: 600;
  }
}

.button-impressionen:hover {
  background-position-x: 100%;
  box-shadow: $light-shadow;
  // transform: translateY(-5px);
  // border: 4px solid #f15025;

  a {
    color: white;
  }
}
