.video-responsive {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 56.25%;
  margin-top: 150px;
  position: relative;
}

.video-responsive iframe {
  top: 0;
  height: 70%;
  width: 70%;
  position: absolute;
  border-radius: 4px;
}
