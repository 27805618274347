h5 {
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
  }
  
  .imprint-container a {
    text-transform: lowercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: none;
    color: $clrPry4;
  }
  
  .imprint-container {
      margin-bottom: 100px;
  }

    
@media screen and (max-width: 1024px) {
  .container{
    text-align: center;
  }
}